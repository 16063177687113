import * as React from "react";
import { ReactStripeElements } from "react-stripe-elements";
import BPointCheckout from './BPointCheckout';
import StripeCheckout from './StripeCheckout';

interface ICheckoutFactoryProps { provider: string, }
export interface ICheckoutProps {
    setComplete: any;
    colorTheme?: string;
    values: any;
    contentId: string;
    setSubmitError: (error: string) => void;
    stripe: ReactStripeElements.StripeProps;

    // updateTokenGenerator: (contentId: number, fixedValues: IDonateFormProps, actions: FormikActions<any>)=> ITokenGeneratorResponseProps;
    updateTokenGenerator?: any;
    setExpiryMonth?: (expiryMonth: string) => void;
    setExpiryYear?: (expiryYear: string) => void;
}
// Add the below to the parent component:
//     
//     const generateTokenRef = React.useRef<any>();
//     const updateTokenGenerator = (func: () => Promise<any>) => {
//         generateTokenRef.current = func;
//     }
//
// To call the function in the parent component:
//
//        if (generateTokenRef.current) {
//              body = await generateTokenRef.current(ContentId, fixedValues, actions);
//        }
//
//
// In the checkout component:
//
//
//          props.updateTokenGenerator(generateBody); 

export default (props: ICheckoutFactoryProps & ICheckoutProps) => {
    const { provider, ...common } = props;
    const Comp = provider == "bpoint" ? BPointCheckout : StripeCheckout;
    if (provider === "bpoint" || (props.stripe !== undefined && provider === "stripe")) {
        return <Comp {...common} />
    } else {
        return <div>ERROR: Unable to load checkout component</div>
    }
}