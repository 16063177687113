import { sanitize } from "dompurify";
import { Formik, FormikActions } from "formik";
import * as moment from "moment";
import * as React from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { injectStripe } from "react-stripe-elements";
import { isEmpty, isNullOrWhiteSpace } from "../../../common/helper/helper";
import { isNullOrWhitespace } from "../../Events/EventList/EventList";
import {
	FormConstants,
	IDonateFormProps,
	WaFormConstants,
} from "../../GenericForm/Components/Constants";
import { CustomFieldType } from "../../GenericForm/Components/FormField";
import {
	createDonateFormValidationSchema,
	formatNumber,
	removeWhiteSpaces,
} from "../../GenericForm/Components/FunctionLib";
import "../../GenericForm/Style/CustomField.scss";
import { getRcTokenAsync, tokenFailable } from "../../GoogleReCaptcha/Recaptcha";
import { ENTITY_TYPE } from "../../hooks/auto-complete";
import "../Style/DonateForm.scss";
import InnerForm from "./InnerForm";
import SinglePageInnerForm from "./SinglePageInnerForm";
import { stepOneCompanyFields } from "./Step1";

export const getUrlParameter = (name: string) => {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
	const results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

export const message = {
	tokenError: "Unable to process payment. Please contact the website administrator.",
};

const initialAmount = getUrlParameter("amount");
const queryInterval = getUrlParameter("interval");
const getIntialIntervalValue = (initialInterval: string) => {
	if (initialInterval && !isNullOrWhiteSpace(initialInterval)) {
		switch (initialInterval.toLowerCase()) {
			case "week":
			case "weekly":
				return "week";
			case "month":
			case "monthly":
				return "month";
			default:
				return "one";
		}
	} else {
		return "one";
	}
};

const initialValues: any = {
	[FormConstants.firstName]: "",
	[FormConstants.lastName]: "",
	[FormConstants.address]: "",
	[FormConstants.suburb]: "",
	[FormConstants.postcode]: "",
	[FormConstants.phone]: "",
	[FormConstants.email]: "",
	[FormConstants.state]: "",
	[FormConstants.donationRecipient]: "",
	[FormConstants.donationDeclaration]: false,
	[WaFormConstants.entityType]: ENTITY_TYPE.Individual,
	[WaFormConstants.companyName]: "",
	[WaFormConstants.companyAddress]: "",
	[WaFormConstants.companySuburb]: "",
	[WaFormConstants.companyState]: "",
	[WaFormConstants.companyPostcode]: "",
	[WaFormConstants.contactFirstName]: "",
	[WaFormConstants.contactLastName]: "",
	[WaFormConstants.contactEmail]: "",
	[WaFormConstants.contactPhone]: "",
	[WaFormConstants.abn]: "",
};

if (initialAmount && parseInt(initialAmount) > 0) {
	initialValues[FormConstants.amount] = initialAmount;
}

export interface ICampaign {
	website_name: string;
	code: string;
	account: string;
}

const DonateForm = (props: IDonateFormProps): React.FunctionComponentElement<IDonateFormProps> => {
	const [campaigns, setCampaigns] = React.useState<JSX.Element[]>();
	const [donationRecipient, setDonationRecipient] = React.useState<string | undefined>();
	const isWaForm = props.rootNode == "2";
	React.useEffect(() => {
		if (isWaForm) {
			fetch(`/Umbraco/Api/FinanceService/GetCampaignList?contentId=${props.contentId}`, {
				method: "GET",
			})
				.then((resp) => resp.json())
				.then((data) => {
					if (!isEmpty(data) && data.data != undefined && !isEmpty(data.data)) {
						const campaignList = data.data as ICampaign[];
						const key = props.waEnhancedMode ? "code" : "website_name";
						if (
							props.defaultDonateDes &&
							campaignList.find(
								(c) => c[key] && c[key].trim() == props.defaultDonateDes
							)
						) {
							const des = campaignList.find(
								(c) => c[key] && c[key].trim() == props.defaultDonateDes
							);
							setDonationRecipient(`${des.code},${des.account}`);
						}
						{
							!props.waEnhancedMode &&
								setCampaigns(
									data.data.map((campaign: ICampaign, index: number) => (
										<option
											key={index}
											value={[campaign.code, campaign.account]}
										>
											{campaign.website_name}
										</option>
									))
								);
						}
					}
				});
		} else {
			if (
				props.donationRecipients &&
				props.defaultDonateDes &&
				props.donationRecipients.find((d) => d == props.defaultDonateDes)
			) {
				setDonationRecipient(props.defaultDonateDes);
			}
			setCampaigns(
				props.donationRecipients.map((opt: string, index: number) => (
					<option key={index} value={opt}>
						{opt}
					</option>
				))
			);
		}
	}, []);

	const handleResponse = async (resp: Response, actions: FormikActions<IDonateFormProps>) => {
		const jsonRes = await resp.json();
		if (jsonRes.Success === false || !resp.ok) {
			setSubmitError(`${jsonRes.Message !== undefined ? jsonRes.Message : ""}`);
			actions.setSubmitting(false);
		} else {
			setStepNum(3);
			if (!isNullOrWhiteSpace(props.redirectUrl)) {
				if (props.redirectTarget == "_blank") {
					setTimeout(() => window.open(props.redirectUrl, props.redirectTarget), 5000);
				} else {
					setTimeout(() => (window.location.href = props.redirectUrl), 5000);
				}
			}
		}
		if (props.landingPage !== undefined && props.landingPage && props.hideLandingPage)
			props.hideLandingPage();
	};

	const [expiryMonth, setExpiryMonth] = React.useState("");
	const [expiryYear, setExpiryYear] = React.useState("");
	const [stepNum, setStepNumber] = React.useState(0);
	const [stepImmediateNum, setStepImmediateNumber] = React.useState(0);
	const [step0Class, setStep0Class] = React.useState("in");
	const [step1Class, setStep1Class] = React.useState("outRight");
	const [step2Class, setStep2Class] = React.useState("outRight");
	const [step3Class, setStep3Class] = React.useState("outRight");
	const [submitError, setSubmitError] = React.useState("");
	const [submitDisabled, setSubmitDisabled] = React.useState(true);
	const [submitClass, setSubmitClass] = React.useState("");
	const [continueDisabled, setContinueDisabled] = React.useState(true);
	const [continueClass, setContinueClass] = React.useState("");
	const [backDisabled, setBackDisabled] = React.useState(false);
	const [backClass, setBackClass] = React.useState(props.colorTheme + "HoverButton");
	const [paymentInputComplete, setPaymentInputComplete] = React.useState(false);
	const [declarationAccepted, setDeclarationAccepted] = React.useState(false);

	React.useEffect(() => {
		if (
			!paymentInputComplete ||
			((props.donationDeclaration || isWaForm) && !declarationAccepted)
		) {
			setSubmitDisabled(true), setSubmitClass("");
		} else {
			setSubmitDisabled(false), setSubmitClass(props.colorTheme + "HoverButton");
		}
	}, [paymentInputComplete, declarationAccepted]);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const generateTokenRef = React.useRef<any>();

	const updateTokenGenerator = (func: () => Promise<any>) => {
		generateTokenRef.current = func;
	};
	const buttonAmountOnClick = (amount: number, setFieldValue: any) => {
		setStepNum(stepNum + 1);
		setFieldValue(FormConstants.amount, amount);
	};

	const submitFunction = async (values: any, actions: FormikActions<any>) => {
		const rcToken = getRcTokenAsync("donate", executeRecaptcha);

		const fixedValues: any = { ListOfExtraFields: {} };
		if (typeof values[FormConstants.amount] !== "number") {
			fixedValues[FormConstants.amount] = parseFloat(
				formatNumber(values[FormConstants.amount])
			);
		} else {
			fixedValues[FormConstants.amount] = values[FormConstants.amount];
		}
		const dr = donationRecipient ?? values[FormConstants.donationRecipient];
		if (props.rootNode == "2" && typeof dr === "string") {
			const campaignArray = dr.split(",");
			fixedValues[WaFormConstants.campaign] = campaignArray[0];
			fixedValues[WaFormConstants.account] = campaignArray[1];
			if (
				isNullOrWhitespace(fixedValues[WaFormConstants.campaign]) &&
				isNullOrWhitespace(fixedValues[WaFormConstants.account]) &&
				!props.waEnhancedMode
			) {
				fixedValues[WaFormConstants.campaign] = "Bcent";
				fixedValues[WaFormConstants.account] = "alpfin2";
			}

			if (expiryMonth !== "" && expiryYear !== "") {
				fixedValues[FormConstants.expiryMonth] = expiryMonth;
				fixedValues[FormConstants.expiryYear] = expiryYear;
			}
		} else {
			fixedValues[WaFormConstants.campaign] = dr;
		}

		if (!isNullOrWhiteSpace(values[FormConstants.paypalSubscriptionId])) {
			fixedValues["ListOfExtraFields"][FormConstants.paypalSubscriptionId] =
				values[FormConstants.paypalSubscriptionId];
		}
		if (!isNullOrWhiteSpace(values[FormConstants.paypalTransactionId])) {
			fixedValues["ListOfExtraFields"][FormConstants.paypalTransactionId] =
				values[FormConstants.paypalTransactionId];
		}
		if (props.donationDeclaration || isWaForm) {
			fixedValues["ListOfExtraFields"]["DeclarationAccepted"] = true;
		}

		Object.entries(values).forEach(([key, value]) => {
			if (key !== FormConstants.amount && key !== FormConstants.donationRecipient) {
				if (!(key === FormConstants.interval && value === "one")) {
					const customField = props.customFields.find(
						(field) => removeWhiteSpaces(field.Name) === key
					);
					if (customField) {
						if (customField.CustomFieldType == CustomFieldType.DatePicker) {
							fixedValues["ListOfExtraFields"][key] =
								moment(value).format("DD/MM/YYYY");
						} else if (customField.CustomFieldType == CustomFieldType.TimePicker) {
							fixedValues["ListOfExtraFields"][key] = moment(value).format("hh:mm a");
						} else {
							fixedValues["ListOfExtraFields"][key] =
								typeof values[key] === "string"
									? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
									: values[key];
						}
					} else if (
						isWaForm &&
						values[WaFormConstants.entityType] === ENTITY_TYPE.Company &&
						stepOneCompanyFields.includes(key)
					) {
						fixedValues["ListOfExtraFields"][key] =
							typeof values[key] === "string"
								? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
								: values[key];
						//so we do not mess with the email autorespond
						if (key === WaFormConstants.contactEmail) {
							fixedValues[FormConstants.email] =
								typeof values[key] === "string"
									? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
									: values[key];
						}
					} else {
						fixedValues[key] =
							typeof values[key] === "string"
								? sanitize(values[key].trim(), { ALLOWED_TAGS: [] })
								: values[key];
					}
				}
			}
		});
		const ContentId = parseInt(props.contentId, 10);
		let body = {};
		if (generateTokenRef.current) {
			try {
				if (fixedValues["PaymentProvider"] === "paypal") {
					body = {
						...fixedValues,
						ContentId,
						RcToken: await tokenFailable(rcToken),
					};
				} else {
					body = await generateTokenRef.current(
						ContentId,
						fixedValues,
						actions,
						fixedValues[WaFormConstants.account],
						rcToken
					);
				}
				if (!body === false) {
					fetch("/Umbraco/Api/DonateForm/Submit", {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							...body,
							Currency: props?.defaultCurrency,
							formContentId: props.formContentId,
						}),
					}).then((resp) => {
						handleResponse(resp, actions);
					});
				} else {
					setSubmitError(message.tokenError);
					actions.setSubmitting(false);
				}
			} catch (e) {
				setSubmitError(e.message);
				actions.setSubmitting(false);
			}
		} else {
			setSubmitError(message.tokenError);
			actions.setSubmitting(false);
		}
	};

	const setStepNum = (step: number) => {
		setStepImmediateNumber(step);
		switch (step) {
			case 0:
				if (stepNum === 0) {
					setStep1Class("outRight");
					setStep0Class("in");
				} else if (stepNum === 1) {
					setStep0Class("in");
					setStep1Class("outRight");
				}
				break;
			case 1:
				if (stepNum === 0) {
					setStep0Class("outLeft");
					setStep1Class("in");
				} else if (stepNum === 2) {
					setStep1Class("in");
					setStep2Class("outRight");
				}
				break;
			case 2:
				setStep1Class("outLeft");
				setStep2Class("in");
				break;
			case 3:
				setStep2Class("outLeft");
				setStep3Class("in");
		}
		setTimeout(() => setStepNumber(step), 200);
	};
	const validationSchema = props.isInternaltionalPostCode
		? createDonateFormValidationSchema(
				(props.donationDeclaration !== undefined &&
					props.donationDeclaration !== "" &&
					props.donationDeclaration !== null) ||
					isWaForm,
				props.enableCountryField,
				props.findDonorByEmailaddress,
				props.customFields,
				["postcode"]
		  )
		: createDonateFormValidationSchema(
				(props.donationDeclaration !== undefined &&
					props.donationDeclaration !== "" &&
					props.donationDeclaration !== null) ||
					isWaForm,
				props.enableCountryField,
				props.findDonorByEmailaddress,
				props.customFields
		  );

	if (queryInterval && !isNullOrWhiteSpace(queryInterval)) {
		initialValues[FormConstants.interval] = getIntialIntervalValue(
			props.singlePageForm ? "" : queryInterval
		);
	} else {
		initialValues[FormConstants.interval] = getIntialIntervalValue(
			props.singlePageForm ? "" : props.initialInterval
		);
	}

	if (props.donateAmounts?.length == 1) {
		initialValues[FormConstants.amount] = props.donateAmounts[0];
	}

	if (props.enableCountryField) {
		initialValues[FormConstants.country] = "";
		initialValues[FormConstants.state] = "Other";
	}
	if (props.customFields) {
		Object.entries(props.customFields).forEach(([key, value]) => {
			if (value.CustomFieldType == CustomFieldType.Checkbox) {
				initialValues[removeWhiteSpaces(value.Name)] = false;
			} else if (value.CustomFieldType == CustomFieldType.CheckboxGroup) {
				initialValues[removeWhiteSpaces(value.Name)] = [];
			} else {
				initialValues[removeWhiteSpaces(value.Name)] = "";
			}
		});
	}
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submitFunction}
			children={(renderProps) =>
				props.singlePageForm ? (
					<SinglePageInnerForm
						{...props}
						{...renderProps}
						stepNum={stepNum}
						setSubmitDisabled={setSubmitDisabled}
						setSubmitClass={setSubmitClass}
						setSubmitError={setSubmitError}
						updateTokenGenerator={updateTokenGenerator}
						setPaymentInputComplete={setPaymentInputComplete}
						submitError={submitError}
						submitClass={submitClass}
						submitDisabled={submitDisabled}
						setExpiryMonth={setExpiryMonth}
						setExpiryYear={setExpiryYear}
						campaigns={campaigns}
						declarationAccepted={declarationAccepted}
						setDeclarationAccepted={setDeclarationAccepted}
						findDonorByEmailaddress={props.findDonorByEmailaddress}
						disableAlternativePayment={
							(props.donationDeclaration || isWaForm) && !declarationAccepted
						}
					/>
				) : (
					<InnerForm
						{...props}
						{...renderProps}
						stepNum={stepNum}
						stepImmediateNum={stepImmediateNum}
						setStepNum={setStepNum}
						setSubmitDisabled={setSubmitDisabled}
						setSubmitClass={setSubmitClass}
						setContinueDisabled={setContinueDisabled}
						setContinueClass={setContinueClass}
						setBackDisabled={setBackDisabled}
						setBackClass={setBackClass}
						setSubmitError={setSubmitError}
						buttonAmountOnClick={buttonAmountOnClick}
						updateTokenGenerator={updateTokenGenerator}
						setPaymentInputComplete={setPaymentInputComplete}
						step0Class={step0Class}
						step1Class={step1Class}
						step2Class={step2Class}
						step3Class={step3Class}
						backClass={backClass}
						continueClass={continueClass}
						submitError={submitError}
						submitClass={submitClass}
						backDisabled={backDisabled}
						continueDisabled={continueDisabled}
						submitDisabled={submitDisabled}
						setExpiryMonth={setExpiryMonth}
						setExpiryYear={setExpiryYear}
						campaigns={campaigns}
						declarationAccepted={declarationAccepted}
						setDeclarationAccepted={setDeclarationAccepted}
						findDonorByEmailaddress={props.findDonorByEmailaddress}
						disableAlternativePayment={
							(props.donationDeclaration || isWaForm) && !declarationAccepted
						}
					/>
				)
			}
		/>
	);
};

export const StripelessDonateForm = DonateForm;
export default injectStripe(DonateForm);
