import { Field, Form, FormikProps } from "formik";
import Checkbox from "formik-antd/es/checkbox";
import * as React from "react";
import LoadingOverlay from "react-loading-overlay";
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	RedditIcon,
	RedditShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
} from "react-share";
import { ClipLoader } from "react-spinners";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import {
	FormConstants,
	IDonateFormProps,
	WaFormConstants
} from "../../GenericForm/Components/Constants";
import { formatNumber } from "../../GenericForm/Components/FunctionLib";
import { AUTOCOMPLETE_FORM_TYPE, ENTITY_TYPE, useAutoComplete } from "../../hooks/auto-complete";
import { PaypalButton } from "../../PaypalButton";
import { CustomFormFieldFactory } from "../Components/FormField";
import "../Style/DonateForm.scss";
import CheckoutFactory from "./CheckoutFactory";
import ErrorMessage from "./ErrorMessage";
import FormField from "./FormField";
import { createCurrencySign } from "./Step0";
import {
	stepOneAllFields,
	stepOneCompanyFields,
	StepOneFieldFactory,
	stepOneStandardFields,
} from "./Step1";

interface IInnerFormProps {
	setSubmitDisabled: (disabled: boolean) => void;
	setSubmitClass: (submitClass: string) => void;
	setSubmitError: (submitError: string) => void;
	updateTokenGenerator: (func: () => Promise<any>) => void;
	setPaymentInputComplete: (complete: boolean) => void;
	setExpiryMonth: (expiryMonth: string) => void;
	setExpiryYear: (expiryYear: string) => void;
	setDeclarationAccepted: (declarationAccepted: boolean) => void;
	declarationAccepted: boolean;
	submitError: string;
	submitClass: string;
	submitDisabled: boolean;
	rootNode: string;
	campaigns?: JSX.Element[];
	stepNum: number;
	setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
	disableAlternativePayment: boolean;
}

const SinglePageInnerForm: React.FC<
	IDonateFormProps & FormikProps<IDonateFormProps> & IInnerFormProps
> = (props) => {
	const { values, setFieldValue, setFieldTouched, errors } = props;
	const isWaForm = props.rootNode == "2";
	const isWaCompany =
		isWaForm && props.values[WaFormConstants.entityType] === ENTITY_TYPE.Company;

	const getStyle = () => {
		const style = {} as React.CSSProperties;
		if (props.landingPage !== undefined && props.landingPage) {
			style.background = "transparent";
		}
		if (props.narrowForm && !props.landingPage) {
			style.width = "400px";
			style.maxWidth = "100%";
		}
		return style;
	};
	React.useEffect(() => {
		if (props.isSubmitting) {
			props.setSubmitDisabled(true);
			props.setSubmitClass("");
		}
	}, [props.isSubmitting]);

	React.useEffect(() => {
		if (
			(props.donationDeclaration || isWaForm) &&
			values[FormConstants.donationDeclaration] === true &&
			props.declarationAccepted === false
		) {
			props.setDeclarationAccepted(true);
		} else {
			if (props.declarationAccepted === true) props.setDeclarationAccepted(false);
		}
	}, [values]);

	const {
		loading: isFetchingDonorData,
		autocompletedFields,
		setAutocompletedFields,
	} = useAutoComplete({
		emailAddress: values[FormConstants.email],
		entityType: isWaCompany ? ENTITY_TYPE.Company : ENTITY_TYPE.Individual,
		autocompleteType: AUTOCOMPLETE_FORM_TYPE.DonateForm,
		contactEmailAddress: values[WaFormConstants.contactEmail],
		errors,
		setFieldTouched,
		setFieldValue,
		skip: !props.findDonorByEmailaddress,
	});

	const currencySign = createCurrencySign(props?.defaultCurrency);

	const numberMask = createNumberMask({
		prefix: currencySign,
		integerLimit: 6,
		allowDecimal: true,
	});
	let shareUrl = props.shareUrl !== "" ? props.shareUrl : location.href;

	const amountSelected =
		values[FormConstants.amount] !== undefined &&
		values[FormConstants.amount] !== null &&
		values[FormConstants.amount] > 0;

	const fieldsToCheck = [
		...(isWaCompany
			? stepOneCompanyFields
			: props.enableCountryField
			? stepOneAllFields
			: stepOneStandardFields),
		...props.customFields?.map((field) => field.Name),
	];
	const allFieldsFilledOut =
		!fieldsToCheck.some((field) => props.errors[field]) &&
		fieldsToCheck.every(
			(field) => values[field] !== undefined && values[field] !== null && values[field] !== ""
		);

	const autoCompleted = autocompletedFields
		? Object.values(autocompletedFields).some((value) => value === true)
		: false;
	return (
		<Form>
			<div
				className={
					`${props.disableGradient ? "" : "donGradient"} donForm ` + props.colorTheme
				}
				style={getStyle()}
			>
				{props.stepNum !== 3 ? (
					<>
						<div>
							<p
								className={
									`${
										props.singlePageForm
											? "donFormHeaderSinglePage"
											: "donFormHeader"
									} donOpposite` + props.colorTheme
								}
							>
								{props.formHeader}
							</p>
							{props.formHeaderSubtext && (
								<p
									className={
										"donFormHeaderSubtext donOpposite" + props.colorTheme
									}
								>
									{props.formHeaderSubtext}
								</p>
							)}
							<hr className="donLine" />
							<div className="rootContainerThing" style={{ marginTop: "20px" }}>
								<div className="container">
									<div
										className={
											props.narrowForm || props.fieldsAsColumn
												? "donSinglePageAmounts col-md-12 my-1 px-2"
												: "donSinglePageAmounts"
										}
									>
										{props.donateAmounts
											.filter((d: number, i: number) => i <= 4)
											.map((donateAmount: number, index: number) => {
												return (
													<div
														key={index}
														className="amountButtonWrapper"
													>
														<button
															className={`donAmountButton ${
																props.colorTheme
															}button${
																donateAmount ===
																values[FormConstants.amount]
																	? "Selected"
																	: ""
															} ${
																props.buttonRoundedCorners
																	? "donButtonRoundedCorners"
																	: ""
															}`}
															type="button"
															key={index}
															onClick={() =>
																props.setFieldValue(
																	FormConstants.amount,
																	donateAmount
																)
															}
														>
															{currencySign}
															{donateAmount}
														</button>
													</div>
												);
											})}

										<Field
											name="amount"
											render={(field: any) => {
												return (
													<div className="donFieldAmountWrapper">
														<MaskedInput
															{...field}
															value={values[FormConstants.amount]}
															onChange={(e) =>
																props.setFieldValue(
																	FormConstants.amount,
																	formatNumber(e.target.value)
																)
															}
															className={`donFieldAmount ${
																values[FormConstants.amount] &&
																!props.donateAmounts.find(
																	(a) =>
																		a ===
																		values[FormConstants.amount]
																)
																	? props.colorTheme +
																	  "buttonSelected"
																	: "donFieldAmount" +
																	  props.colorTheme
															} ${
																props.buttonRoundedCorners
																	? "donButtonRoundedCorners"
																	: ""
															}`}
															mask={numberMask}
															guide={false}
															placeholder="Other"
															style={{
																height: "inherit",
																fontWeight: "bolder",
															}}
														/>
													</div>
												);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="container">
							{isWaForm ? (
								<div className="row">
									<Field
										className={"donOpposite" + props.colorTheme}
										component="div"
										style={{ display: "flex" }}
										name={WaFormConstants.entityType}
									>
										<div className="donRadioWrapper form-check">
											<input
												defaultChecked={
													values[WaFormConstants.entityType] ===
													ENTITY_TYPE.Individual
												}
												name={WaFormConstants.entityType}
												type="radio"
												value="individual"
												className="form-check-input"
												id="individual"
												style={{ cursor: "pointer" }}
											/>
											<label
												className="form-check-label"
												style={{ cursor: "pointer" }}
												htmlFor="individual"
											>
												I donate as an individual
											</label>
										</div>
										<div className="donRadioWrapper form-check">
											<input
												defaultChecked={
													values[WaFormConstants.entityType] ===
													ENTITY_TYPE.Company
												}
												name={WaFormConstants.entityType}
												type="radio"
												value="company"
												className="form-check-input"
												id="company"
												style={{ cursor: "pointer" }}
											/>
											<label
												className="form-check-label"
												style={{ cursor: "pointer" }}
												htmlFor="company"
											>
												I donate on behalf of an association or company
											</label>
										</div>
									</Field>
								</div>
							) : null}
							<div className="row">
								<Field
									className={"donFrequency donOpposite" + props.colorTheme}
									component="div"
									name={FormConstants.interval}
									onChange={(c) =>
										props.setFieldValue(
											FormConstants.interval,
											c.target.checked === true ? "month" : "one"
										)
									}
								>
									<div className="donRadioWrapper form-check">
										<input
											style={{ cursor: "pointer" }}
											defaultChecked={
												values[FormConstants.interval] === "month"
											}
											name={FormConstants.interval}
											type="checkbox"
											className="form-check-input"
											id="month"
										/>
										<label
											style={{ cursor: "pointer" }}
											className="form-check-label"
											htmlFor="month"
										>
											Make my donation monthly
										</label>
									</div>
								</Field>
							</div>
						</div>
						{amountSelected && (
							<>
								<div className="container singlePageSection">
									{props.findDonorByEmailaddress && (
										<div className="row">
											{isWaCompany ? (
												<StepOneFieldFactory
													isWaForm={isWaForm}
													fieldName={WaFormConstants.contactEmail}
													{...props}
													isColumn={true}
												/>
											) : (
												<StepOneFieldFactory
													isWaForm={isWaForm}
													fieldName={FormConstants.email}
													{...props}
													isColumn={true}
												/>
											)}
											{autoCompleted && (
												<p
													className={
														"col-md-12 donTaxMessage donOpposite" +
														props.colorTheme
													}
													style={{ marginBottom: 0, marginTop: 10 }}
												>
													Welcome back! If your address or phone number
													has changed since the last time you donated,{" "}
													<label
														style={{
															textDecoration: "underline",
															cursor: "pointer",
														}}
														onClick={() => {
															setAutocompletedFields(undefined);
															props.setFieldValue(
																FormConstants.address,
																""
															);
															props.setFieldValue(
																FormConstants.firstName,
																""
															);
															props.setFieldValue(
																FormConstants.lastName,
																""
															);
															props.setFieldValue(
																FormConstants.phone,
																""
															);
															props.setFieldValue(
																FormConstants.postcode,
																""
															);
															props.setFieldValue(
																FormConstants.state,
																""
															);
															props.setFieldValue(
																FormConstants.suburb,
																""
															);
															props.setFieldValue(
																WaFormConstants.companyName,
																""
															);
															props.setFieldValue(
																WaFormConstants.abn,
																""
															);
															props.setFieldValue(
																WaFormConstants.companyAddress,
																""
															);
															props.setFieldValue(
																WaFormConstants.companySuburb,
																""
															);
															props.setFieldValue(
																WaFormConstants.companyState,
																""
															);
															props.setFieldValue(
																WaFormConstants.companyPostcode,
																""
															);
															props.setFieldValue(
																WaFormConstants.contactFirstName,
																""
															);
															props.setFieldValue(
																WaFormConstants.contactLastName,
																""
															);
															props.setFieldValue(
																WaFormConstants.contactPhone,
																""
															);
														}}
													>
														click here to update them.
													</label>
												</p>
											)}
										</div>
									)}
									<LoadingOverlay
										active={isFetchingDonorData}
										className={"loadingOverlay"}
										spinner={
											<ClipLoader sizeUnit={"px"} size={20} color={"white"} />
										}
									>
										<div className="row">
											{(isWaCompany ? stepOneCompanyFields : stepOneAllFields)
												.filter((field) => {
													if (
														props.findDonorByEmailaddress &&
														(field === FormConstants.email ||
															field === WaFormConstants.contactEmail)
													) {
														return false;
													} else if (autocompletedFields) {
														if (autocompletedFields[field] === false) {
															return true;
														} else {
															return false;
														}
													} else {
														return true;
													}
												})
												.map((f, i) => (
													<StepOneFieldFactory
														key={i}
														fieldName={f}
														isWaForm={isWaForm}
														{...props}
														isColumn={
															props.fieldsAsColumn || props.narrowForm
														}
													/>
												))}
										</div>
									</LoadingOverlay>
									<div className="row">
										{props?.customFields?.map((f, i) => {
											return CustomFormFieldFactory(
												f,
												props.fieldsAsColumn || props.narrowForm,
												i,
												props.errors,
												props.touched,
												props.colorTheme,
												props.setFieldValue
											);
										})}
									</div>
								</div>
								{props.campaigns && props.campaigns.length > 0 && (
									<div className="singlePageSection">
										<p className={"donCauseText"}>
											I wish to donate to the following campaign:
										</p>
										<FormField
											colorTheme={props.colorTheme}
											isColumn={props.fieldsAsColumn || props.narrowForm}
											type="select"
											className="form-control standard-form-group"
											name={FormConstants.donationRecipient}
											component="select"
											placeholder="Campagin*"
											errors={props.errors}
											touched={props.touched}
											children={props.campaigns}
										/>
									</div>
								)}
							</>
						)}
						{allFieldsFilledOut && (
							<div className="singlePageSection">
								<p
									className={"donTaxMessage donOpposite" + props.colorTheme}
									style={{ marginBottom: 0, marginTop: 10 }}
								>
									Contributions are tax deductible.
								</p>
								<div className="row justify-content-center">
									<CheckoutFactory
										provider={props.paymentProvider}
										setComplete={props.setPaymentInputComplete}
										colorTheme={props.colorTheme}
										values={values}
										contentId={props.contentId}
										setSubmitError={props.setSubmitError}
										stripe={props.stripe}
										updateTokenGenerator={props.updateTokenGenerator}
										setExpiryMonth={props.setExpiryMonth}
										setExpiryYear={props.setExpiryYear}
									/>
								</div>
								{values[FormConstants.amount] &&
									props.paypalClientId !== undefined &&
									props.paypalClientId !== "" && (
										<PaypalButton
											contentId={props.contentId}
											textColorClass={"donOpposite" + props.colorTheme}
											paymentProvider={props.paymentProvider}
											setFieldValue={props.setFieldValue}
											setSubmitError={props.setSubmitError}
											submitForm={props.submitForm}
											amount={values[FormConstants.amount]}
											interval={values[FormConstants.interval]}
											paypalClientId={props.paypalClientId}
											disabled={props.disableAlternativePayment}
											transactionDescription="Donation"
										/>
									)}
							</div>
						)}

						<div className="donProgressArea container">
							<div className="donSubmitErrorMessage">{props.submitError}</div>
							<div className="donErrorMessages">
								{Object.keys(values).map((key, index) => {
									if (props.touched[key] && props.errors[key]) {
										return (
											<ErrorMessage
												errors={props.errors}
												touched={props.touched}
												colorTheme={props.colorTheme}
												name={key}
												key={index}
											/>
										);
									}
								})}
							</div>
							{(props.donationDeclaration || isWaForm) && allFieldsFilledOut ? (
								<div className="container">
									<div
										style={{ textAlign: "center" }}
										className={`checkbox-group-wrapper my-2 px-4 col-md-12 ${
											props.touched[FormConstants.donationDeclaration] &&
											props.errors[FormConstants.donationDeclaration]
												? "error-validation-" + props.colorTheme
												: ""
										}`}
									>
										<Checkbox name={FormConstants.donationDeclaration}>
											{isWaForm
												? "I confirm that I am an Australian citizen or resident and not otherwise prohibited from donating under Australian or Western Australian electoral law"
												: props.donationDeclaration}
										</Checkbox>
									</div>
								</div>
							) : (
								<></>
							)}
							<div className="progress-bar-buttons">
								<button
									className={`donButton ${props.colorTheme}ContSubButton ${
										props.submitClass
									} ${
										props.buttonRoundedCorners ? "donButtonRoundedCorners" : ""
									}`}
									type="submit"
									disabled={props.submitDisabled || !allFieldsFilledOut}
								>
									Donate
								</button>
							</div>
						</div>

						{props.formImageUrl && !props.narrowForm && (
							<div className="donImageWrapper">
								<img
									className="donImage img-fluid p-0"
									src={props.formImageUrl}
								></img>
							</div>
						)}
					</>
				) : (
					<>
						<div className="container singlePageSection" style={{ marginBottom: 20 }}>
							<div className="justify-content-center">
								<h1 className={"donFormHeader donOpposite" + props.colorTheme}>
									Thank you for your donation.
								</h1>
								<p className={"donThankYou donOpposite" + props.colorTheme}>
									{props.tYMessage}
								</p>
							</div>
							<div>
								<h1 className={"donFormHeader donOpposite" + props.colorTheme}>
									{createCurrencySign(props?.defaultCurrency)}
									{values[FormConstants.amount] &&
										Number(values[FormConstants.amount])}
								</h1>
							</div>
							<div className="donShareButtons">
								<div className="donShareButton">
									<FacebookShareButton
										url={shareUrl}
										quote={props.shareMessage}
										hashtag={props.shareHashtag}
									>
										<FacebookIcon round size={30} />
									</FacebookShareButton>
								</div>
								<div className="donShareButton">
									<TwitterShareButton
										url={shareUrl}
										hashtags={[props.shareHashtag]}
									>
										<TwitterIcon round size={30} />
									</TwitterShareButton>
								</div>
								<div className="donShareButton">
									<WhatsappShareButton url={shareUrl} title={props.shareTitle}>
										<WhatsappIcon round size={30} />
									</WhatsappShareButton>
								</div>
								<div className="donShareButton">
									<LinkedinShareButton url={shareUrl}>
										<LinkedinIcon round size={30} />
									</LinkedinShareButton>
								</div>
								<div className="donShareButton">
									<RedditShareButton url={shareUrl} title={props.shareTitle}>
										<RedditIcon round size={30} />
									</RedditShareButton>
								</div>
								<div className="donShareButton">
									<EmailShareButton
										url={shareUrl}
										subject={props.shareTitle}
										body={props.shareMessage}
									>
										<EmailIcon round size={30} />
									</EmailShareButton>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</Form>
	);
};

export default SinglePageInnerForm;
