import {
	PayPalButtons,
	PayPalScriptProvider,
	usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { FormConstants } from "../GenericForm/Components/Constants";
import React, { useEffect } from "react";

interface IPaypalButtonProps {
	setFieldValue: (field: string, value: any) => void;
	textColorClass: string;
	paymentProvider: string; //we need this to restore the passed in payment provider in case we somehow do not complete the paypal transaction
	submitForm: () => void;
	paypalClientId: string;
	contentId: string;
	disabled: boolean;
	amount: string;
	interval: "week" | "month" | "one";
	transactionDescription: string;
	setSubmitError?: (error: string) => void;
}

function InnerPaypalButton({
	textColorClass,
	contentId,
	disabled,
	paymentProvider,
	setFieldValue,
	setSubmitError,
	submitForm,
	amount,
	transactionDescription,
	interval,
}: IPaypalButtonProps) {
	const [{ options, isResolved }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
		if (interval === "one") {
			dispatch({
				type: "resetOptions",
				value: {
					...options,
					vault: false,
					intent: "capture",
				},
			});
		} else {
			dispatch({
				type: "resetOptions",
				value: {
					...options,
					vault: true,
					intent: "subscription",
				},
			});
		}
	}, [interval]);

	if (!isResolved) return null;

	return (
		<div
			className="container justify-content-center"
			style={{ marginTop: 10, textAlign: "center", width: "min-content" }}
		>
			<p className={textColorClass} style={{ marginBottom: 10, marginTop: 10 }}>
				Or use:
			</p>
			{interval === "one" ? (
				<PayPalButtons
					disabled={disabled}
					style={{ layout: "horizontal", tagline: false }}
					onApprove={(data, actions) => {
						return actions.order.capture().then((orderData) => {
							if (orderData.status === "COMPLETED") {
								setFieldValue(
									FormConstants.paypalTransactionId,
									orderData.purchase_units[0].payments.captures[0].id
								);
								submitForm();
							}
						});
					}}
					onCancel={() => {
						setFieldValue("PaymentProvider", paymentProvider);
					}}
					onError={(error) => {
						setFieldValue("PaymentProvider", paymentProvider);
						setSubmitError?.(error.message as string);
					}}
					onClick={(data, actions) => {
						setFieldValue("PaymentProvider", "paypal");
					}}
					createOrder={(data, actions) => {
						return actions.order.create({
							intent: "CAPTURE",
							purchase_units: [
								{
									amount: {
										value: Number(amount).toFixed(2).toString(),
										currency_code: "AUD",
									},
									description: transactionDescription,
								},
							],
						});
					}}
				/>
			) : (
				<PayPalButtons
					disabled={disabled}
					style={{ layout: "horizontal", tagline: false }}
					onCancel={() => {
						setFieldValue("PaymentProvider", paymentProvider);
					}}
					onError={(error) => {
						setFieldValue("PaymentProvider", paymentProvider);
						setSubmitError?.(error.message as string);
					}}
					onClick={(data, actions) => {
						setFieldValue("PaymentProvider", "paypal");
					}}
					createSubscription={async (data, actions) => {
						const body = {
							interval,
							amount: Number(amount).toFixed(2).toString(),
							contentId: contentId,
						};
						return await fetch("/Umbraco/Api/Paypal/PaypalPlanId", {
							method: "POST",
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
							body: JSON.stringify({
								...body,
							}),
						}).then(async (resp) => {
							const response = await resp.json();
							if (response.PlanId) {
								return actions.subscription.create({
									plan_id: response.PlanId,
								});
							}
						});
					}}
					onApprove={(data, actions: any) => {
						return actions.subscription.get().then((subscription) => {
							if (subscription?.id) {
								setFieldValue(FormConstants.paypalSubscriptionId, subscription.id);
								submitForm();
							}
						});
					}}
				/>
			)}
		</div>
	);
}

export const PaypalButton = (props: IPaypalButtonProps) => {
	return (
		<PayPalScriptProvider
			options={{
				"client-id": props.paypalClientId,
				currency: "AUD",
				vault: false,
				intent: "capture",
			}}
		>
			<InnerPaypalButton {...props} />
		</PayPalScriptProvider>
	);
};
